$bgcolor: #f0cc99;
$fgcolor: #000;
$newslink: #333;
$newshover: #fff;
$articlebg: #fff;
$buttonbg: #666;
$buttonfg: #f0f0f0;
$buttonshadow: rgba(0, 0, 0, 0.5);
$linkfg: #030303;
$cd-text-bg: #fff;
$headerbg: #fff;
$blog-header-bg: #ccc;

body {
    margin: 0;
    background-color: $bgcolor;
    background-image: url("/images/prbg2.jpg");
    background-position: 0 10px;
    background-repeat: no-repeat;
    font-family: "Open Sans", "Lucida Grande", Helvetica, Verdana, Arial, sans-serif;
    font-size: 14px;
    padding: 0;
}

h2 {
    margin-bottom: 0;
    padding: 0.25em 0;
}

img {
    border: 0;
}

a {
    text-decoration: none;
}

.frontpage {
    background-image: url("/images/prbg4.jpg");
    background-position: 0 0;

    .button {
        &:link,
        &:visited,
        &:active {
            color: $buttonfg;
        }
    }

    a:link,
    a:visited,
    a:active {
        color: $linkfg;
        font-weight: bold;
    }

    .cd {
        float: right;
        margin-right: 20px;
        margin-top: 5px;
        padding: 0;

        p {
            margin: 0;
            background-color: $cd-text-bg;
            opacity: 0.85;
            padding: 5px;
            padding-bottom: 5px;
        }

        h2 {
            margin: 0;
            background-color: $cd-text-bg;
            font-size: 120%;
            font-style: normal;
            opacity: 0.85;
            padding: 0 5px;
        }
    }

    dt {
        margin-bottom: 0;
    }

    dd {
        margin-left: 0;

        p {
            margin-top: 0;
        }
    }
}

/* event/gig styles */

.clear {
    clear: both;
}

.links dt {
    font-variant: small-caps;
    font-weight: normal;
}

.links dd {
    font-size: 90%;
    padding-bottom: 10px;
}

header {
    background-color: $headerbg;
    margin-top: 0;
    opacity: 0.85;
    padding: 0;
    text-align: right;

    h1 {
        display: inline;
        margin: 2px;
        font-size: 120%;
    }

    p {
        display: inline;
        margin: 2px 5px 2px 15px;
    }
}

.itms {
    text-align: right;

    .fullsize {
        margin-right: 1px;
    }
}

.msize {
    display: none;
}

.button {
    display: inline-block;
    background-color: $buttonbg;
    border-radius: 8px;
    box-shadow: 0 2px 6px $buttonshadow;
    color: $buttonfg;
    cursor: pointer;
    font-size: 1.25em;
    font-weight: bold;
    margin-bottom: 1em;
    margin-left: 0;
    padding: 5px;
}

.prwide {
    display: block;
}

.prmobile {
    display: none;
}

.cd::after {
    display: block;
    clear: both;
    content: ".";
    height: 0;
    visibility: hidden;
}

.floater {
    float: left;
    margin-bottom: 20px;
    margin-left: 15px;
    max-width: 400px;
}

@media (max-width: 1214px) {
    .cd {
        width: 422px;
    }
}

.media {
    margin: 10px;

    .img {
        float: left;
        margin-right: 10px;
    }

    .img img {
        display: block;
    }
}

.media,
.bd {
    overflow: hidden;
    zoom: 1;
}

.bd {
    min-width: 50%;

    .img img {
        border: 0;
        border-radius: 0;
        padding: 0;
        width: 128px;
    }
}

.blogentry {
    background-color: $articlebg;
    padding: 0.5em;

    h1 {
        background-color: $blog-header-bg;
        padding: 0.25em;
    }
}

.frontsection {
    padding: 0 0.25rem;

    & > h2 {
        background-color: lighten($bgcolor, 15%);
        margin-left: -0.25rem;
        margin-right: -0.25rem;
        padding: 0.25em;
    }
}

@import "gigs";
@import "respond";
@import "mo";
