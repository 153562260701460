// later: this is the widescreen stuff

.wrapper {
    display: grid;
    grid-template-areas: "header" "main" "sidebar" "footer";
    @media screen and (min-width: 780px) {
        grid-template-areas: "header header" "main sidebar" "footer footer";
        grid-template-columns: 1fr 350px;

        &.page {
            grid-template-areas: "header header header" "dummy main dummy2" "footer footer footer";
            grid-template-columns: 1fr 3fr 1fr;
        }
    }
}

.sidebar {
    display: grid;
    align-self: start;
    grid-area: sidebar;
    grid-template-areas: "sales" "news" "shows" "elsewhere" "contact";
    padding: 0 1em;
}

.cd {
    display: flex;
    align-items: normal;
    flex-wrap: wrap;
    grid-area: main;

    @media screen and (min-width: 780px) {
        justify-content: center;
    }
}

.floater {
    display: inline-block;
    float: none;
    margin-bottom: 20px;
    margin-left: 15px;
    max-width: 400px;
}

.itms {
    grid-area: sales;
}

.header {
    grid-area: header;
}

footer {
    grid-area: footer;
}

.news {
    grid-area: news;
}

.shows {
    grid-area: shows;
}

.contact,
.mailinglist {
    grid-area: contact;
}

.elsewhere {
    grid-area: elsewhere;
}

.main {
    grid-area: main;
}

@media only screen and (max-width: 40em) {
    body {
        background-image: none;
    }
}
