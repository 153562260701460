$gigdesc-bg: #f0f0f0;
$giglink-under: #00c;
$desc-margin: 1em;

.gigaddress {
    margin-top: 0;
}

.addresspart {
    margin: 0;
}

.gigdesc {
    background-color: $gigdesc-bg;
    margin-top: $desc-margin;
    padding: $desc-margin;
}

.gigtitle {
    margin-bottom: 0;

    & > a {
        &,
        &:link,
        &:visited {
            border-bottom: 1px solid transparent;
            color: $fgcolor;
            text-decoration: none;
        }

        &:hover,
        &:active {
            border-bottom: 1px solid $giglink-under;
            text-decoration: none;
        }
    }
}

.gigdate {
    margin-top: 0.25em;
}

.gig {
    margin-bottom: 2em;
}

.gig_section {
    max-height: 600px;
    max-height: 80vh;
    overflow: scroll;
}
