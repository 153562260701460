$linkbutton-bg: #fff;
$linkbutton-fg: 666;
$cd-bg: #666;
$cd-fg: #ccc;

@media screen and (max-device-width: 480px) {
    .msize {
        display: block;
    }

    body .fullsize {
        display: none;
    }

    .linkbutton {
        display: block;
        margin: 0 0 5px;
        background-color: $linkbutton-bg;
        border-radius: 4px;
        color: $linkbutton-fg;
        padding: 8px 5px;
        width: auto;
    }

    .itms {
        text-align: left;
    }

    .frontpage {
        background-image: none;

        .cd {
            float: none;
            margin-right: 0;
            padding: 2px;
            width: auto;
        }

        .cd h2 {
            display: block;
            background-color: $cd-bg;
            color: $cd-fg;
            margin-bottom: 0;
            margin-left: 0;
            margin-top: 0;
            padding-bottom: 0;
            padding-left: 2px;
            padding-right: 2px;

            a,
            a:active,
            a:link,
            a:visited {
                color: $cd-fg;
            }
        }

        .linkbutton a {
            &,
            &:link,
            &:visited {
                color: $linkbutton-fg;
            }
        }

        header {
            text-align: center;

            h1 {
                display: block;
                margin: 2px;
                font-size: 120%;
                text-align: center;
            }

            p {
                display: block;
            }
        }
    }

    .prnarrow {
        display: block;
    }

    .prwide {
        display: none;
    }

    .floater {
        float: none;
        margin-left: 0;
    }
}
